import React from 'react';
import clsx from 'clsx';
import i18n from 'i18next';
import i18nInit from './i18n/i18n';
import { hydrate, render } from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { Analytics } from '@aws-amplify/analytics';
import to from 'await-to-js';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import Leaflet from 'leaflet';
import { HelmetProvider } from 'react-helmet-async';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import querystring from 'query-string';
// import TagManager from 'react-gtm-module';

import './global';
import awsconfig from './aws-exports.js';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CustomAppBar from 'components/CustomAppBar';
import IndividualClientAppBar from 'components/IndividualClientAppBar';
import Loading from 'components/Loading';
import { Colors, DRAWER_WIDTH, SHOP_PREFIX, GROUP_ORDER_PREFIX, liffIds } from '@silvergatedelivery/constants';
import Winbox from 'components/Winbox/Winbox';
import { routes as publicRoutes } from 'views/Public/routes';
import store from './App.reducer';
import Footer from './Footer';
import UserGroupSelector from 'components/Auth/UserGroupSelector';
import cache from 'utilities/cache';
import appConfig from '../package.json';
import Reset from 'components/Reset';
import useCustomAuth from 'hooks/useCustomAuth';
import PublicOrder from 'components/Order/PublicOrder';
import { CacheProvider, useCache } from 'CacheProvider';
import { getUserGroupOptions } from 'components/Auth/helpers';
import IndividualClientLandingPage from 'views/Public/Menu2/Menu';
import liff from '@line/liff';

// CSS
import './index.css';
import 'leaflet/dist/leaflet.css';
import 'winbox-react/dist/index.css';

// // Google Tag Manager
// // https://www.npmjs.com/package/react-gtm-module
// const tagManagerArgs = process.env.NODE_ENV === 'development' ? {
//   // local development
//   gtmId: 'GTM-PFQFKG8Q',
//   auth: '42SR7kw4tRK8p02hxCgNjQ',
//   preview: 'env-4',
// } : {
//   // live
//   gtmId: 'GTM-PFQFKG8Q',
//   auth: 'Pkcz1Ep3TdKboXO0uZwA6w',
//   preview: 'env-1',
// };

// TagManager.initialize(tagManagerArgs);

// Leaflet
Leaflet.Icon.Default.imagePath = '../node_modules/leaflet';
delete Leaflet.Icon.Default.prototype._getIconUrl;
Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// eslint-disable-next-line no-undef
const browserChannel = new BroadcastChannel('silvergate');

// Disable oauth for web
delete awsconfig.oauth;

const { origin } = window.location;

const envName = awsconfig.aws_cloud_logic_custom[0].endpoint.split('/').pop();

awsconfig.oauth = {
  domain: `oauth-${envName}.auth.ap-southeast-1.amazoncognito.com`,
  scope: ['email', 'openid', 'profile'],
  redirectSignIn: `${origin}/`,
  redirectSignOut: `${origin}/`,
  responseType: 'code', // token
};

Amplify.configure(awsconfig);
Analytics.disable();

const history = createBrowserHistory();

// https://material-ui.com/customization/default-theme/
const theme = createTheme({
  palette: {
    primary: {
      light: Colors.primaryLight,
      main: Colors.primary,
      dark: Colors.primaryDark,
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiTab: {
      root: {
        minWidth: `100px !important`,
      },
    },
  },
});

const amplifyTheme = {
  name: 'amplify-theme',
  // https://github.com/aws-amplify/amplify-ui/tree/main/packages/ui/src/theme/tokens/components
  tokens: {
    components: {
      authenticator: {
        modal: {
          backgroundColor: {
            value: Colors.background.light,
          },
        },
      },
    },
    colors: {
      font: {
        primary: { value: Colors.primary },
      },
      brand: {
        primary: {
          // 10: { value: Colors.primary },
          // 20: { value: Colors.primary },
          // 30: { value: Colors.primary },
          // 40: { value: Colors.primary },
          // 50: { value: Colors.primary },
          60: { value: Colors.primary },
          70: { value: Colors.primary },
          80: { value: Colors.primary },
          90: { value: Colors.primary },
          100: { value: Colors.primary },
        },
      },
    },
  },
};

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 64,
    overflow: 'auto',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: Colors.background.light,
    marginLeft: DRAWER_WIDTH,
    height: 'calc(100vh - 64px)',
  },
  contentShift: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  root: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      overflowX: 'hidden',
    },
    // height: 'calc(100vh - 116px)', // 64px appbar + 52px footer
  },
}));

function ReactApp() {
  const classes = useStyles();

  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [user, setUser] = React.useState();
  const [filteredRoutes, setFilteredRoutes] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const { signOut, recordEvent } = useCustomAuth();
  const [i18nReady, setI18nReady] = React.useState(false);
  const [needToGetUserGroupOptions, setNeedToGetUserGroupOptions] = React.useState(true);
  const [shopClientMode, setShopClientMode] = React.useState(
    window.location.hostname.startsWith(SHOP_PREFIX));
  const [orderClientMode, setOrderClientMode] = React.useState(
    window.location.hostname.startsWith(GROUP_ORDER_PREFIX));
  const { setUserGroupOptions, setAppGroup, selectedUserGroupParams, setSelectedUserGroupParams } = useCache();
  const oldInstanceFound = React.useRef(false);

  const checkAppVersion = async () => {
    const version = appConfig.version;
    const versionInCache = cache.get('app:version');
    if (!versionInCache || version !== versionInCache) {
      await signOut();
      return false;
    }
    return true;
  };
  const publicOrderPathRegex = /^\/order\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/;

  React.useEffect(() => {
    (async () => {
      await i18nInit();
      setI18nReady(true);
    })();
    if (shopClientMode) {
      liff
        .init({
          liffId: liffIds[window.location.hostname] || liffIds.default,
        })
        .then(() => {
          console.log('LIFF init successed');
          // (async () => {
          //   console.log(liff.isLoggedIn());
          //   if (liff.isLoggedIn()) {
          //     console.log('get line id token');
          //     const token = liff.getIDToken();
          //     const decodedToken = liff.getDecodedIDToken();
          //     console.log(token);
          //     console.log(decodedToken);

          //     const profile = await liff.getProfile();
          //     console.log(profile);
          //     const cred = await Auth.federatedSignIn('access.line.me', {
          //       token,
          //       expires_at: decodedToken.exp,
          //     }, {
          //       // email: 'ccfang@gmail.com',
          //       name: profile.displayName,
          //       sub: profile.userId,
          //     });
          //     console.log(cred);
          //   }
          // })();
        })
        .catch((e) => {
          console.log('LIFF init failed...');
          console.log(`code: ${e.code}, message: ${e.message}`);
        });
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      if (!await checkAppVersion()) {
        return;
      }
      const initialPath = history.location;
      global.logger.debug(`initialPath`, initialPath);

      const [err, cognitoUser] = await to(Auth.currentAuthenticatedUser({ bypassCache: true }));
      // global.logger.debug('cognitoUser', { err, cognitoUser, initialPath });

      if (err) {
        if (!publicRoutes.some((route) => route.path === initialPath.pathname)) {
          const match = initialPath.pathname.match(publicOrderPathRegex);
          if (initialPath.pathname !== '/reset' && !match) {
            history.push('/');
          }
        }
      } else {
        setUser(cognitoUser);
      }

      if (initialPath.pathname === '/') {
        const { clientId } = querystring.parse(window.location.search);
        if (clientId) {
          cache.set('public:menu-target-client-id', clientId);
        } else {
          // TODO
          // cache.remove('public:menu-target-client-id');
        }
      }

      setIsLoading(false);
    })();

    Hub.listen('auth', ({ payload: { event, data } }) => {
      // global.logger.debug({ event, data });
      switch (event) {
        case 'signIn':
          setUser(data);
          break;
        case 'user':
          setUser(data);
          break;
        case 'signOut':
          setUser();
          setOpen(false);
          setIsSignedIn(false);
          break;
        default:
      }
    });

    const unlisten = history.listen((location) => {
      setShopClientMode(window.location.hostname.startsWith(SHOP_PREFIX));
      setOrderClientMode(window.location.hostname.startsWith(GROUP_ORDER_PREFIX));
    });

    return () => {
      unlisten();
    };
  }, []);

  React.useEffect(() => {
    (async () => {
      if (!user) {
        return;
      }
      // 當用line login時，重新導向回來會同時有兩個setUser，造成這個useEffect同時跑兩次，會create兩個client
      if (!needToGetUserGroupOptions) {
        return;
      }
      setNeedToGetUserGroupOptions(false);
      const userGroupOptions = await getUserGroupOptions(user, orderClientMode, shopClientMode);

      setUserGroupOptions(userGroupOptions);
      const isAdmin = userGroupOptions.some(({ userGroupName }) => userGroupName === 'Admins');
      // validate selectedUserGroupParams
      if (selectedUserGroupParams) {
        if (!isAdmin) {
          if (selectedUserGroupParams.userGroupName === 'Admins') {
            setSelectedUserGroupParams();
          } else if (selectedUserGroupParams.userGroupName === 'OrgAdmins') {
            const organization = userGroupOptions.find(({ organizationId }) => organizationId === selectedUserGroupParams.organizationId);
            if (!organization) {
              console.error('organization not found');
              setSelectedUserGroupParams();
            }
          } else if (selectedUserGroupParams.userGroupName === 'FacilityAdmins') {
            const facility = userGroupOptions.find(({ clientId }) => clientId === selectedUserGroupParams.clientId);
            if (!facility) {
              console.error('facility not found');
              setSelectedUserGroupParams();
            }
          }
        }
        if (selectedUserGroupParams.userGroupName === 'OrgAdmins') {
          const organization = userGroupOptions.find(({ organizationId }) => organizationId === selectedUserGroupParams.organizationId);
          if (organization && organization.organizationData.updatedAt !== selectedUserGroupParams.organizationData.updatedAt) {
            console.error('organization modified');
            setSelectedUserGroupParams();
          }
        } else if (selectedUserGroupParams.userGroupName === 'FacilityAdmins') {
          const facility = userGroupOptions.find(({ clientId }) => clientId === selectedUserGroupParams.clientId);
          if (facility && facility.clientData.updatedAt !== selectedUserGroupParams.clientData.updatedAt) {
            console.error('facility modified');
            setSelectedUserGroupParams();
          }
        }
      }

      // #1057 紀錄關閉網頁時間
      window.addEventListener('beforeunload', async () => {
        // 不一定會成功
        await recordEvent('關閉網頁');
      });
    })();
  }, [user]);

  React.useEffect(() => {
    (async () => {
      if (!user || !user.signInUserSession) {
        setFilteredRoutes([]);
        return;
      }

      if (isSignedIn) {
        return;
      }

      setIsSignedIn(true);

      setIsLoading(false);
    })();
  }, [isSignedIn, user]);

  React.useEffect(() => {
    (async () => {
      if (!isSignedIn || !user || !selectedUserGroupParams || !i18nReady) return;

      setIsLoading(true);

      // Solve the issue for group permissions
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await new Promise((resolve) => {
        const { refreshToken } = cognitoUser.getSignInUserSession();
        cognitoUser.refreshSession(refreshToken, (err, session) => {
          resolve();
        });
      });

      const {
        userGroupName,
        clientId,
        clientData,
        organizationId,
        organizationData,
      } = selectedUserGroupParams;

      // // GTM trigger
      // TagManager.dataLayer({
      //   dataLayerName: userGroupName,
      //   dataLayer: {
      //     event: '登入',
      //     username: cognitoUser.username,
      //     clientId,
      //     organizationId,
      //   },
      // });

      // clear old values
      cache.remove('app:organizationId');
      // cache.remove('app:name');
      cache.remove('app:facilityId');
      cache.remove('app:phoneNumber');
      cache.remove('app:clientId');

      // TODO: put all cache into CacheProvider
      switch (userGroupName) {
        case 'Admins':
          setAppGroup('Admins');
          cache.set('app:pathPrefix', 'admin');
          break;
        case 'OrgAdmins':
          setAppGroup('OrgAdmins');
          cache.set('app:pathPrefix', 'organization');

          cache.set('app:organizationId', organizationId);
          cache.set('app:name', organizationData.name);
          break;
        case 'FacilityAdmins':
          setAppGroup('FacilityAdmins');
          cache.set('app:pathPrefix', 'facility');

          cache.set('app:facilityId', clientId);
          cache.set('app:name', clientData.name);
          cache.set('app:location', clientData.county);
          cache.set('app:phoneNumber', clientData.phoneNumber);
          break;
        case 'Clients':
        default:
          setAppGroup('Clients');
          cache.set('app:pathPrefix', 'client');

          cache.set('app:clientId', clientId);
          cache.set('app:name', clientData.name);
          cache.set('app:location', clientData.county);
          cache.set('app:phoneNumber', clientData.phoneNumber);
      }

      cache.set('app:selectedUserGroupParams', selectedUserGroupParams);

      if (clientData && clientData.translation) {
        await i18n.changeLanguage(clientData.translation);
      }

      const userGroups = [userGroupName];

      // load routes here for translation
      const { appRoutes } = require('./routes');
      const filteredRoutes = appRoutes.filter(({ roles }) => {
        return (roles) ? userGroups && userGroups.some((group) => roles.includes(group)) : true;
      });
      setFilteredRoutes(filteredRoutes);

      const initialPath = history.location;
      global.logger.debug(`initialPath`, initialPath);

      history.push(initialPath);

      const username = cache.get('app:username');

      browserChannel.onmessage = (event) => {
        if (event.data.type === 'echo') {
          browserChannel.postMessage({
            type: 'response',
            userGroupName,
            clientId,
            organizationId,
            username,
          });
        } else if (event.data.type === 'response') {
          if (event.data.userGroupName === userGroupName &&
            event.data.clientId === clientId &&
            event.data.organizationId === organizationId &&
            event.data.username === username
          ) {
            oldInstanceFound.current = true;
          }
        }
      };

      const { type: navigationType } = window.performance.getEntriesByType('navigation')[0];
      if (navigationType !== 'reload') {
        browserChannel.postMessage({ type: 'echo' });
        setTimeout(async () => {
          if (!oldInstanceFound.current) {
            await recordEvent('登入');
          } else {
            await recordEvent('開新網頁');
          }
        }, 1000);
      }

      setIsLoading(false);
    })();
  }, [isSignedIn, selectedUserGroupParams, user, i18nReady]);

  if (window.location.pathname === '/reset') {
    return <Reset />;
  }

  const match = window.location.pathname.match(publicOrderPathRegex);
  if (match && match[1]) {
    return <PublicOrder id={match[1]} />;
  }

  if (isLoading || !i18nReady) {
    return (<Loading />);
  }

  if (user && !selectedUserGroupParams) {
    return (
      <UserGroupSelector
        user={user}
        onSelectedUserGroup={setSelectedUserGroupParams}
        individualClientMode={shopClientMode}
      />);
  }

  const getCustomComponent = (path, component) => {
    if (path === '/' && shopClientMode) {
      component = IndividualClientLandingPage;
    }
    return component;
  };

  return (
    <Router history={history}>
      {shopClientMode ?
        <IndividualClientAppBar
          user={user}
          routes={filteredRoutes}
          open={open}
          onUpdate={setOpen}
        /> :
        <CustomAppBar
          user={user}
          routes={filteredRoutes}
          open={open}
          onUpdate={setOpen}
        />}
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: !open,
        })}>
        <div className={classes.root} style={{ height: shopClientMode ? 'calc(100vh - 64px)' : 'calc(100vh - 116px)' }}>
          <Switch>
            <Route path="/app" component={App} />
            {user ?
              <Route path="/">
                { shopClientMode ? <IndividualClientLandingPage /> : <App user={user} /> }
              </Route> :
              <React.Fragment>
                {/* <Route path="/blogs" exact component={Blogs} />
                <Route path="/blogs/:id" exact component={Blog} /> */}
                {/* <Route path="/download" exact component={Download} /> */}
                {/* <Route path="/" exact component={LandingPage} /> */}
                {publicRoutes.map(({ path, exact, component }, index) => {
                  const customComponent = getCustomComponent(path, component);
                  return <Route key={index} path={path} exact={exact} component={customComponent} />;
                })}
              </React.Fragment>}
          </Switch>
        </div>
        {!shopClientMode &&
        <Footer />}
      </div>
    </Router>
  );
}

const RootApp = () => (
  // <React.StrictMode>
  <HelmetProvider>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <div>
          <ReduxToastr
            timeOut={10000}
            newestOnTop={false}
            preventDuplicates
            position='top-right'
            transitionIn='fadeIn'
            transitionOut='fadeOut'
            progressBar
            closeOnToastrClick={false} />
        </div>
      </Provider>
      <AmplifyProvider theme={amplifyTheme}>
        <CacheProvider>
          <ReactApp />
        </CacheProvider>
      </AmplifyProvider>
      <Winbox />
    </ThemeProvider>
  </HelmetProvider>
);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<RootApp />, rootElement);
} else {
  render(<RootApp />, rootElement);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
