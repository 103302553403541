/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://ucywsg6kz5hdxp37dyu2rvdciq.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-7syjq24ctnbirlhlmvz4qkpjnu",
    "aws_cloud_logic_custom": [
        {
            "name": "webhook",
            "endpoint": "https://i58tud0hhd.execute-api.ap-southeast-1.amazonaws.com/fangthree",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-1:90d6fab3-54f0-4549-8282-05f2d311058d",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_yHXMlq4FM",
    "aws_user_pools_web_client_id": "46vgkmt07lg5eoqlga3kfvcv0r",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "silvergate639f5cfdeddb42f78ca0c4a2d0471c8f83155-fangthree",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
