import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

import { asyncListAll } from 'utilities/graph';
import { getPropsByMode } from './helpers';

export default function DataJoinEditorInput({
  title = '',
  showHelperText = true,
  mode = '',
  joinData,
  defaultValues,
  onChange,
  onUpdateOptions,
  disabled,
  freeSolo: inFreeSolo = true,
  variant = 'standard',
  loadOnOpen = false,
  fullWidth = false,
}) {
  const [allOptions, setAllOptions] = useState([]);
  const [displayedOptions, setDisplayedOptions] = useState([]);
  const [values, setValues] = useState(defaultValues);
  const [freeSolo, setFreeSolo] = useState(inFreeSolo);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event, values) => {
    const newValues = values.map((value) => value.replace(/^新增：/, ''));
    setValues([...newValues]);
    onChange(newValues.map((value) => {
      return allOptions.find(({ label }) => label === value) || {
        label: value,
      };
    }));
  };

  useEffect(() => {
    setDisplayedOptions(allOptions
      .map((option) => option.label)
      .sort((a, b) => a > b ? 1 : -1));
  }, [allOptions]);

  // useEffect(() => {
  //   if (!joinData) return;
  //   const values = joinData.map((item) => item.tag.label);
  //   setDefaultValues(values);
  //   setValues(values);
  // }, [joinData]);

  useEffect(() => {
    if (loadOnOpen && !isOpen) return;

    (async () => {
      const {
        listOptionsQueryName,
        listOptionsQueryOptions,
        freeSolo,
      } = getPropsByMode(mode);
      const results = await asyncListAll(listOptionsQueryName, { ...listOptionsQueryOptions, limit: 1000 });

      setAllOptions(results);

      if (!freeSolo) {
        setFreeSolo(false);
      }

      if (onUpdateOptions) {
        onUpdateOptions(results);
      }
    })();
  }, [mode, loadOnOpen, isOpen]);

  if (!defaultValues) return null;

  return (
    <React.Fragment>
      <Autocomplete
        multiple
        id="tags-filled"
        options={displayedOptions}
        defaultValue={defaultValues}
        value={values}
        freeSolo={freeSolo}
        disableCloseOnSelect
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label={title} placeholder="" variant={variant} />
        )}
        onChange={handleChange}
        disabled={disabled}
        aria-describedby="tags-filled-helper-text"
        onOpen={()=>{
          setIsOpen(true);
        }}
        fullWidth={fullWidth}
        filterOptions={(options, params) => {
          const filtered = options.filter((option) =>
            option.toLowerCase().includes(params.inputValue.toLowerCase()),
          );
          if (params.inputValue !== '' && freeSolo) {
            if (!filtered.find((option) => option === params.inputValue)) {
              filtered.push(`新增：${params.inputValue}`);
            }
          }
          return filtered;
        }}
        noOptionsText='沒有符合所輸入的選項'
      />
      {showHelperText &&
        <FormHelperText id="tags-filled-helper-text">幫助</FormHelperText>}
    </React.Fragment>
  );
}

DataJoinEditorInput.propTypes = {
  projectId: PropTypes.string,
  title: PropTypes.string,
  mode: PropTypes.string,
  joinData: PropTypes.array,
  defaultValues: PropTypes.array,
  onChange: PropTypes.func,
  onUpdateOptions: PropTypes.func,
  disabled: PropTypes.bool,
  freeSolo: PropTypes.bool,
  showHelperText: PropTypes.bool,
  variant: PropTypes.string,
  loadOnOpen: PropTypes.bool,
  fullWidth: PropTypes.bool,
};
